export const WebsiteCardSkeleton = () => <div className="w-full rounded-2xl bg-white overflow-hidden" data-sentry-component="WebsiteCardSkeleton" data-sentry-source-file="website-list-skeleton.tsx">
    {/* Image section */}
    <div className="aspect-[16/9] w-full bg-gray-200 rounded-t-2xl animate-pulse" />

    {/* Content section */}
    <div className="p-4 bg-white rounded-b-2xl">
      {/* Tags */}
      <div className="flex gap-2 mb-3">
        <div className="h-6 w-16 bg-gray-200 rounded-full animate-pulse" />
        <div className="h-6 w-16 bg-gray-200 rounded-full animate-pulse" />
      </div>

      {/* Title - two lines */}
      <div className="space-y-2 mb-4">
        <div className="h-7 bg-gray-200 rounded w-full animate-pulse" />
        <div className="h-7 bg-gray-200 rounded w-3/4 animate-pulse" />
      </div>

      {/* Bottom section with avatar and metrics */}
      <div className="flex items-center justify-between">
        {/* User info */}
        <div className="flex items-center space-x-2">
          <div className="h-8 w-8 bg-gray-200 rounded-full animate-pulse" />
          <div className="h-6 w-24 bg-gray-200 rounded animate-pulse" />
        </div>

        {/* Metrics */}
        <div className="flex items-center space-x-4">
          <div className="h-6 w-12 bg-gray-200 rounded animate-pulse" />
          <div className="h-6 w-12 bg-gray-200 rounded animate-pulse" />
        </div>
      </div>
    </div>
  </div>;
export const WebsiteListSkeleton = () => <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 max-w-screen-2xl mx-auto" data-sentry-component="WebsiteListSkeleton" data-sentry-source-file="website-list-skeleton.tsx">
    {[...Array(8)].map((_, index) => <WebsiteCardSkeleton key={index} />)}
  </div>;